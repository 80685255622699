import React, { useEffect, useState } from 'react'
import dateFormat from "dateformat";
function Feed({filter = ""}) {
    let [posts, setPosts] = useState([]);
    useEffect(() => {
        (async() => {
            try {
                let data = await fetch('https://pbn.deliveryclouds.com/api/user/getPost?category='+filter);
                const getData = await data.json();
                setPosts(getData.posts);
            } catch (error) {
                setPosts([]);
            }
        })();
    }, [filter]);
    
    return (
        <section class="blog-content-section p-80">
            <div class="container">
                <ul>
                    {(posts).map((feed) => (
                        <li>
                            <div class="row">
                                <div class="col-md-3 col-sm-3 col-lg-3">
                                    <div class="blog-img">
                                        <img src={feed.image} class="img-fluid" alt={feed.image} />
                                    </div>
                                </div>
                                <div class="col-md-9 col-sm-9 col-lg-9">
                                    <div class="b-content">
                                        <h3>{feed.title}</h3>
                                        <h5>by <span class="b_category">Admin</span> <i class="fa-solid fa-period"></i> <span class="b_date">{ dateFormat(feed.createdAt, "mmmm, dd, yyyy")}</span></h5>
                                        <p>{feed.description}</p>
                                        <div class="readmore_block"><a href={feed.url} target="_blank">Read More</a></div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default Feed
