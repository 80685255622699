import React, { useEffect, useState } from "react";

// ICONS
import * as FaIcons from "react-icons/fa"; //Now i get access to all the icons
import * as AiIcons from "react-icons/ai";

import { IconContext } from "react-icons";
// ROUTING

import { Link } from "react-router-dom";
import logo from './images/logo.png';
// STYLES
import "./Navbar.css";

function Side({setFilter, filter=''}) {
    const [sidebar, setSidebar] = useState(false);
    const [category, setCategory] = useState([]);

    const showSidebar = () => setSidebar(!sidebar);
    
    const filterCategory = (item_id) => {
      setFilter(item_id);
    }
    useEffect(() => {
        (async() => {
            try {
                let data = await fetch("https://pbn.deliveryclouds.com/api/user/getCategory");
                const getData = await data.json();
                setCategory(getData.category);   
            } catch (error) {
                setCategory([]);
            }
        })();
    },[]);
  
    return (
      <>
        <IconContext.Provider value={{ color: "#000000" }}>
          {/* All the icons now are white */}
          <div className="navbar">
            <Link to="#" className="menu-bars">
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
          </div>
          <div class="site_logo text-center">
            <img src={logo} />
        </div>
          <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
            <ul className="nav-menu-items" onClick={showSidebar}>
              <li className="navbar-toggle">
                <Link to="#" className="menu-bars">
                  Categories <AiIcons.AiOutlineClose />
                </Link>
              </li>
              <li className={filter == '' ? "sidebar-menu active" : "sidebar-menu"}>
                <Link data-id="123" onClick={() => filterCategory('')}>
                    <span>All News</span>
                </Link>
              </li>
              {category.map((item, index) => {
                return (
                  <li key={index} className={filter == item._id ? "sidebar-menu active" : "sidebar-menu"}>
                    <Link onClick={() => filterCategory(item._id)}>
                      <img src={item.category_image}/>
                      <span>{item.name}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </IconContext.Provider>
      </>
    );
}

export default Side
