import React, { useState } from 'react'
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Feed from './Feed';
import Footer from './Footer';
import Side from './Side';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
function App() {
  const [filter, setFilter] = useState();
  return (
    <div className="App">
      <Router>
        <Side setFilter={setFilter} filter={filter}/>
        <Feed filter={filter}/>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
