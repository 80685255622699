import React from 'react'
import footer_logo from './images/footer-logo.png';
import * as FaIcons from "react-icons/fa";
function Footer() {
    const date = new Date();
    const year = date.getFullYear();
    return (
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="footer-cnt">
                            <div class="footer-logo">
                                <img src={footer_logo} alt="Test"/>
                            </div>
                            <p class="copy-right-text"><FaIcons.FaCopyright /> {year} by Blockchain Matters</p>
                            <div class="footer-link">
                                <ul>
                                    <li><a href=""><FaIcons.FaFacebook /></a></li>
                                    <li><a href="https://instagram.com/blockchainmatters_gl?igshid=YmMyMTA2M2Y=" target="_blank"><FaIcons.FaInstagram /></a></li>
                                    <li><a href="https://www.linkedin.com/company/blockchain-matters/" target="_blank"><FaIcons.FaLinkedin/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer